import { IModuleStateManager } from '@msdyn365-commerce-modules/checkout-utilities';
import { IProductRefinerHierarchy } from '@msdyn365-commerce/commerce-entities';
import { CategoryPath, ProductPrice, SimpleProduct } from "@msdyn365-commerce/retail-proxy";
import { IOrderSummaryLines } from '@msdyn365-commerce-modules/order-summary-utilities';

export interface IModuleStatePropsExt {
    moduleState: IModuleStateManagerExt;
}

export interface IModuleStateManagerExt extends IModuleStateManager {
    init(options?: Partial<IModuleStateManagerExt>): void;
    wide?: boolean;
}

export interface IProductError {
    RecordId: number;
    Error: string;
    Type: 'warning' | 'error';
}

export interface SimpleProductExt extends SimpleProduct {
    OutOfAssortment?: boolean;
    AlcoholPercentage?: string;
    Badge?: string;
    Brand?: string;
    VolumeCl?: string;
    CategoryPaths?: CategoryPath[];
    Manufacturer?: string;
    ProductPrice?: ProductPrice;

    Producer?: string;
    Country?: string;
    District?: string;
    CountryAndDistrict?: string;
    Category?: string;
    Vintage?: string;

    SoldOutUntilDate?: Date;
    SoldOutUnknownDate?: boolean;
}

export interface ProductPriceExt extends ProductPrice {
    OverrideDiscountPercentage?: number;
}

export interface IProductRefinerHierarchyExt extends IProductRefinerHierarchy {
    DisableSortValues?: boolean;
    RangeToPredefinedRanges?: boolean;
}

export interface IOrderSummaryLinesExt extends IOrderSummaryLines {
    charges?: React.ReactNode[];
}

export enum LifeCycleStatus {
    SoldOutUnknownDate = '20'
}
